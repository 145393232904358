import { isLoggedIn, logout } from "utils/auth"

import ClientOnly from "components/client-only"
import Container from "react-bootstrap/Container"
import LocalizedLink from "components/localized-link"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import Navbar from "react-bootstrap/Navbar"
import React from "react"
import { navigate } from "gatsby"
import styles from "./index.module.scss"
import svgLogo from "images/logo/logo.svg"
import useTranslations from "components/use-translations"

export default function Header() {
  const {
    genel: {
      hakkimizda,
      iletisim,
      programlar,
      yazilimlar,
      programMerkezi,
      tusunamiKutuphaneTakipProgrami,
      servishaneTeknikServisProgrami,
      cekilisAdamHizliCekilisSitesi,
      akilliTahtaKilit,
      tusunamiCekilisProgrami,
      indirmeler,
      kodvizitHakkinda,
      sirketBilgileri,
      blog,
      hesapBilgileri,
      kullanimKosullari,
      gizlilikPolitikasi,
      mesafeliSatisSozlesmesi,
      iadeveTeslimatSartlari,
      lisansAl,
    },
  } = useTranslations()
  return (
    <header
      className={`border-bottom shadow-sm sticky-top bg-white ${styles.header}`}
    >
      <Container>
        <Navbar
          collapseOnSelect
          expand="md"
          className="d-flex w-100 px-0 mt-1m-auto"
          style={{ fontSize: 18, lineHeight: "18px" }}
        >
          <Navbar.Brand className="pr-3">
            <LocalizedLink to="/">
              <img
                className="mb-0"
                src={svgLogo}
                width="130"
                alt="Kodvizit Logo"
              ></img>{" "}
            </LocalizedLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav></Nav>
            <Nav className="w-100">
              <NavDropdown title={programlar} className="my-auto pl-md-0">
                <NavDropdown.Header>{yazilimlar}</NavDropdown.Header>
                <NavDropdown.Item as={LocalizedLink} to="/programlar/">
                  {programMerkezi}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/kutuphane-programi/">
                  {tusunamiKutuphaneTakipProgrami}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/servishane/">
                  {servishaneTeknikServisProgrami}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/cekilis-sitesi/">
                  {cekilisAdamHizliCekilisSitesi}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/akilli-tahta-kilit/">
                  {akilliTahtaKilit}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/cekilis-programi/">
                  {tusunamiCekilisProgrami}
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={LocalizedLink}
                to="/indirme/"
                className="my-auto pl-md-2"
              >
                {indirmeler}
              </Nav.Link>
              <NavDropdown title={hakkimizda} className="my-auto pl-md-0">
                <NavDropdown.Header>{kodvizitHakkinda}</NavDropdown.Header>
                <NavDropdown.Item as={LocalizedLink} to="/hakkimizda/">
                  {sirketBilgileri}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/iletisim/">
                  {iletisim}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/blog/">
                  {blog}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/hesap-bilgileri/">
                  {hesapBilgileri}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/kullanim-kosullari/">
                  {kullanimKosullari}
                </NavDropdown.Item>
                <NavDropdown.Item as={LocalizedLink} to="/gizlilik-politikasi/">
                  {gizlilikPolitikasi}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={LocalizedLink}
                  to="/mesafeli-satis-sozlesmesi/"
                >
                  {mesafeliSatisSozlesmesi}
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={LocalizedLink}
                  to="/iade-teslimat-sartlari/"
                >
                  {iadeveTeslimatSartlari}
                </NavDropdown.Item>
              </NavDropdown>
              <div className="ml-auto mr-auto mr-md-0 ">
                <ClientOnly>
                  {isLoggedIn() && (
                    <button
                      className="d-inline-block btn btn-outline-primary btn-sm m-4"
                      onClick={event => {
                        event.preventDefault()
                        logout(() => navigate(`/hesap/giris`))
                      }}
                    >
                      Çıkış Yap
                    </button>
                  )}
                </ClientOnly>
                <div className="d-inline-block">
                  <LocalizedLink
                    to="/magaza/"
                    className="rounded-pill  btn btn-primary text-white font-weight-bold  px-md-4 py-md-1 my-md-2 justify-content-md-center"
                    role="button"
                    style={{ fontSize: 16, width: 150 }}
                  >
                    {lisansAl}
                  </LocalizedLink>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}
