import Footer from "components/footer/"
import Header from "components/header/"
import PropTypes from "prop-types"
import React from "react"
import TopBar from "components/header/topbar"

export default function Layout({ children, className }) {
  return (
    <>
      <TopBar></TopBar>
      <Header></Header>
      <article className={className && className}>{children}</article>
      <Footer></Footer>
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
