const isBrowser = typeof window !== `undefined`

import { SHA256 } from "crypto-js"
import { login } from "utils/api"

const getUser = () =>
  window.localStorage.kodvizitUser
    ? JSON.parse(window.localStorage.kodvizitUser)
    : {}

const setUser = user =>
  (window.localStorage.kodvizitUser = JSON.stringify(user))

export const handleLogin = ({ username, password }, callback) => {
  if (!isBrowser) return false

  const hashedPassword = SHA256(password).toString()

  login(username, hashedPassword)
    .then(resp => resp.json())
    .then(json => {
      if (!json || !json[0]) {
        return false
      }
      const user = json[0]
      setUser(user)
      callback()
    })
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()

  return !!user.mail
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  setUser({})
  callback()
}
