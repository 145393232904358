import {} from "@fortawesome/free-brands-svg-icons"

import {
  faEnvelope,
  faPhoneAlt,
  faRss,
} from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faTelegram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import React from "react"
import Tooltip from "react-bootstrap/Tooltip"
import useTranslations from "components/use-translations"

export default function TopBar() {
  const {
    genel: { whatsappDestek, telegramDestek },
  } = useTranslations()
  return (
    <div className="sticky-top bg-primary">
      <Container>
        <Navbar
          expand="md"
          className="d-none d-md-flex w-100 px-0 m-auto"
          style={{ fontSize: 12, lineHeight: "14px" }}
        >
          <Nav className="mr-auto">

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{whatsappDestek}</Tooltip>}
            >
              <a
                href="https://api.whatsapp.com/send?phone=908504414134&text=Merhaba,%20destek%20istiyorum."
                className="text-light mr-3 font-weight-bold"
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon size="lg" icon={faWhatsapp} />
                </div>{" "}
                0850 441 41 34 (Sadece WhatsApp)
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{telegramDestek}</Tooltip>}
            >
              <a
                href="https://t.me/kodvizit"
                className="text-light mr-3 font-weight-bold"
              >
                <div
                  className="d-inline-block"
                  style={{ width: "16px", height: "16px" }}
                >
                  <FontAwesomeIcon size="lg" icon={faTelegram} />
                </div>{" "}
                kodvizit
              </a>
            </OverlayTrigger>
            <div className="text-light mr-3 font-weight-bold">
              {" "}
              <div
                className="d-inline-block"
                style={{ width: "16px", height: "16px" }}
              >
                <FontAwesomeIcon size="lg" icon={faEnvelope} />
              </div>{" "}
              bilgi@kodvizit.com
            </div>

            <a
              aria-label="Facebook"
              href="https://www.facebook.com/kodvizit"
              className="text-light mr-3 font-weight-bold"
            >
              {" "}
              <div
                className="d-inline-block"
                style={{ width: "16px", height: "16px" }}
              >
                <FontAwesomeIcon size="lg" icon={faFacebookF} />
              </div>
            </a>
            <a
              aria-label="Twitter"
              href="https://www.twitter.com/kodvizit"
              className="text-light mr-3 font-weight-bold"
            >
              {" "}
              <div
                className="d-inline-block"
                style={{ width: "16px", height: "16px" }}
              >
                <FontAwesomeIcon size="lg" icon={faTwitter} />
              </div>
            </a>
            <a
              aria-label="Rss"
              href="https://www.kodvizit.com/rss.xml"
              className="text-light mr-3 font-weight-bold"
            >
              {" "}
              <div
                className="d-inline-block"
                style={{ width: "16px", height: "16px" }}
              >
                <FontAwesomeIcon size="lg" icon={faRss} />
              </div>
            </a>
          </Nav>
        </Navbar>
      </Container>
    </div>
  )
}
